import React from "react"
import PartProgress from "./PartProgress"

const splitCourses = false
const CourseProgress = ({
  data,
  appliesForStudyRight,
  currentCourseVariant,
}) => {
  const quizzes = []
  const programmingExercises = []
  Object.entries(data).forEach(([name, data]) => {
    name.startsWith("part")
      ? quizzes.push([name, data])
      : programmingExercises.push([name, data])
  })

  const correctOrder = [
    "Intro",
    "RDD",
    "dataframe",
    "Mllib",
    "Graphframes",
    "StructuredStreaming",
    "Extra",
  ]

  programmingExercises.sort((x, y) => {
    if (correctOrder.indexOf(x[0]) < correctOrder.indexOf(y[0])) {
      return -1
    } else if (correctOrder.indexOf(x[0]) > correctOrder.indexOf(y[0])) {
      return 1
    }
    return 0
  })

  const programmingExercisesWithoutExtra = programmingExercises.filter(
    ([name, data]) => name !== "Extra",
  )

  const extraExercises = programmingExercises.filter(
    ([name, data]) => name === "Extra",
  )

  extraExercises[0][0] = "Exercises"

  let quizzesNPoints = 0
  let quizzesMaxPoints = 0
  quizzes.forEach(([name, data]) => {
    quizzesNPoints += data.Points.n_points
    quizzesMaxPoints += data.Points.max_points
  })
  let programmingExercisesNPoints = 0
  let programmingExercisesMaxPoints = 0
  programmingExercisesWithoutExtra.forEach(([name, data]) => {
    programmingExercisesNPoints += data.Points.n_points
    programmingExercisesMaxPoints += data.Points.max_points
  })

  let quizzesProgress = 0
  if (quizzesMaxPoints !== 0) {
    quizzesProgress =
      Math.floor((quizzesNPoints / quizzesMaxPoints) * 100) / 100
  }

  let programmingExerciseProgress = 0
  if (programmingExercisesMaxPoints !== 0) {
    programmingExerciseProgress =
      Math.floor(
        (programmingExercisesNPoints / programmingExercisesMaxPoints) * 100,
      ) / 100
  }

  const total = [
    [
      "Quizzes",
      {
        Points: {
          group: "total",
          max_points: quizzesMaxPoints,
          n_points: quizzesNPoints,
          progress: quizzesProgress,
        },
      },
    ],
    [
      "Exercises",
      {
        Points: {
          group: "total",
          max_points: programmingExercisesMaxPoints,
          n_points: programmingExercisesNPoints,
          progress: programmingExerciseProgress,
        },
      },
    ],
  ]

  return (
    <div>
      <h4>Quizzes</h4>
      {quizzes.map(([name, data]) => (
        <PartProgress name={name} data={data} />
      ))}
      <h4>Exercises</h4>
      {programmingExercisesWithoutExtra.map(([name, data]) => (
        <PartProgress name={name} data={data} />
      ))}
      <h4>Total</h4>
      {total.map(([name, data]) => (
        <PartProgress name={name} data={data} />
      ))}
      <h4>Extra</h4>
      {extraExercises.map(([name, data]) => (
        <PartProgress name={name} data={data} />
      ))}
    </div>
  )
}

export default CourseProgress
