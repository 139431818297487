import { fetchMoocFiProgress } from "./moocfi"
import { zip } from "../util/arrays"
import { fetchQuizzesProgress } from "./quizzes"

//eslint-disable-next-line no-unused-vars
const introductionCourseGroups = [
  "osa01",
  "osa02",
  "osa03",
  "osa04",
  "osa05",
  "osa06",
  "osa07",
]

const emptyProgress = {
  part01: {
    Points: {
      group: "osa01",
      progress: 0,
      n_points: 0,
      max_points: 10,
    },
  },
  part02: {
    Points: {
      group: "osa02",
      progress: 0,
      n_points: 0,
      max_points: 10,
    },
  },
  part03: {
    Points: {
      group: "osa03",
      progress: 0,
      n_points: 0,
      max_points: 10,
    },
  },
  part04: {
    Points: {
      group: "osa04",
      progress: 0,
      n_points: 0,
      max_points: 10,
    },
  },
  part05: {
    Points: {
      group: "osa05",
      progress: 0,
      n_points: 0,
      max_points: 10,
    },
  },
  part06: {
    Points: {
      group: "osa06",
      progress: 0,
      n_points: 0,
      max_points: 10,
    },
  },
  part07: {
    Points: {
      group: "osa07",
      progress: 0,
      n_points: 0,
      max_points: 10,
    },
  },
  part08: {
    Points: {
      group: "osa08",
      progress: 0,
      n_points: 0,
      max_points: 10,
    },
  },
  part09: {
    Points: {
      group: "osa09",
      progress: 0,
      n_points: 0,
      max_points: 10,
    },
  },
  part10: {
    Points: {
      group: "osa10",
      progress: 0,
      n_points: 0,
      max_points: 10,
    },
  },
  dataframe: {
    Points: {
      group: "dataframe",
      n_points: 0,
      max_points: 11,
      progress: 0,
    },
  },
  Extra: {
    Points: {
      group: "Extra",
      n_points: 0,
      max_points: 6,
      progress: 0,
    },
  },
  Graphframes: {
    Points: {
      group: "Graphframes",
      n_points: 0,
      max_points: 6,
      progress: 0,
    },
  },
  Intro: {
    Points: {
      group: "Intro",
      n_points: 0,
      max_points: 1,
      progress: 0,
    },
  },
  Mllib: {
    Points: {
      group: "Mllib",
      n_points: 0,
      max_points: 7,
      progress: 0,
    },
  },
  RDD: {
    Points: {
      group: "RDD",
      n_points: 0,
      max_points: 6,
      progress: 0,
    },
  },
  StructuredStreaming: {
    Points: {
      group: "StructuredStreaming",
      n_points: 0,
      max_points: 3,
      progress: 0,
    },
  },
}

export async function fetchProgress(t) {
  const progress = await fetchMoocFiProgress()

  if (progress == null) {
    return emptyProgress
  }

  const progresses = progress.progress
  const progressByGroup = {}

  progresses.forEach((progressEntry) => {
    const group = progressEntry.group.replace("osa", "part")
    if (!progressByGroup[group]) {
      progressByGroup[group] = {}
    }
    const identifier = "Points"
    progressByGroup[group][identifier] = progressEntry
  })

  return progressByGroup
}

export function getEmptyProgress() {
  return emptyProgress
}
